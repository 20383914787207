@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.nav-bar {
  margin-bottom: 10px;

  .nav_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    background-color: hsl(210, 86%, 90%);
    box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
    animation: slideDown 1s ease-out;

    .nav_link {
      text-decoration: none;
      background-color: transparent;
      padding: 10px;
      text-transform: capitalize;
      font-weight: bold;
      color: #020d20;
    }
  }
}

.nav_link:hover {
  cursor: pointer;
  border-bottom: 1px solid #0d2430;
  transition: all 200ms;
}

.menu-content {
  margin: 15px 0 15px 250px;
  width: max-content;
  height: max-content;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: max-content;
  height: max-content;
  transition: all 200ms;
  z-index: 100;
}

.menu__item {
  position: relative;

  &,
  &::before,
  &::after {
    display: inline-block;
    border-radius: 3px;
    width: 33px;
    height: 4px;
    background-color: hsl(210, 86%, 60%);
    z-index: 2;
    cursor: pointer;
    transition: all 200ms;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
  }

  &::before {
    top: -8px;
  }
  &::after {
    top: 8px;
  }
}

.active > .menu__item {
  background-color: transparent;
  transition: all 200ms;

  &::before,
  &::after {
    top: 0;
  }

  &::before {
    rotate: 135deg;
  }

  &::after {
    rotate: -135deg;
  }
}


@media only screen and (min-width: 768px){

  .nav-bar {
    margin-bottom: 0px !important;
  }

  .menu-content {
    display: none;
  }

  .nav_list{
    width: max-content !important;
    height: 100%;
    display: flex !important;
    gap: 15px !important;
}

.nav_link
{
  font-size: 18px;
}



}

@media only screen and (min-width: 1024px){
  .nav_list{
    width: 100% !important;
   flex-direction: row !important;
   
}
.nav_list > li{
  text-align: center;
  width: max-content;
  padding: 10px 0;
  color:#054A91 !important;
  
 
}
.nav_link {
  font-size: 14px;
  
}
}
