

.search_box{
    display: flex;
   flex-direction: row-reverse;
   align-items: center;
    width: 100%;
    height: 100%;
    gap: 5px;
    margin-top: 10px;
 
  }

  .search-icon{
    font-size: 20px;
    color: #124E78;
    font-weight: bolder;
    padding: 2px;
    margin-right: 10px;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 12px;
    padding: 5px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    outline: none;
    transition: all 300ms;
  }
  
  input:focus {
    border: 1px solid rgba(50, 50, 118, 0.458);
    font-weight: 300;
  }

  @media only screen and (min-width: 768px){
    input {
      padding: 10px !important;
    }
  }