@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;



.suture-knot {
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: 15px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
}

.suture-anatomy {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
}

.suture-anatomy > a {
  text-align: center;
  text-decoration: none;
}

.suturing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.see-more:hover,
.needle-more:hover{
  cursor: pointer;
  width: max-content;
  box-shadow: 0 0 5px #276b90;
  padding: 5px;
  border-radius: 50%;
}

.suture-materials {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &::before {
    width: 50px;
    height: 50px;
    position: absolute;
    border-top: 2px solid #519da4;
    border-right: 2px solid #519da4;
    content: "";
    right: 0.5%;
    top: -2px;
  }
}

.suture-material-description {
  text-align: center;
  line-height: 2;
  color: #276b90;
}

.material-lists {
  list-style: none;
  text-align: center;
}

.absorbable-sutures,
.non-absorbable-sutures {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  font-weight: normal;
  color: hsl(0, 0%, 50%);
  line-height: 2; 
}

hr{
    border: 1px solid #519da4;
    width: 100%;
    font-size: 18px;
}


.types-heading {
  font-size: 19px;
}

.surgical-needles{
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
  line-height: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.needle-description {
  color: #276b90;
}


.point{
    font-weight: normal;
    color:hsl(0, 0%, 50%);
}

.needle-heading{
    transform: translateX(-12px);
    color:hsl(0, 0%, 50%);
}

.needle-types {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-bottom: 10px;
  line-height: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.fa-hand-point-right {
  transform: translateX(-12px);
}

.suture-techniques,
.knot-tying {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-shadow:0px 1px 3px 0px rgba(0, 0, 0, 0.1) ,
  0px 1px 2px 0px rgba(0, 0, 0, 0.06) ;
}


@media only screen and (min-width: 768px){

  .suture-knot {
    font-size: 18px;
  }

  .material-lists {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .absorbable-sutures,
  .non-absorbable-sutures {
    display: flex;
    flex-direction: column;
  }
}