@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px){

.nav-img{
  display: flex;
  margin-top: 10px;
}
}

@media only screen and (min-width: 1024px){

.nav-img{
  flex-direction: column;
  gap: 20px;
}
}


