@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.anesthesia-heading{
    color: hsl(0, 0%, 50%);
}

.anesthesia{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding: 5px;
    line-height: 2;
    color:#276B90;
    font-weight: bold;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
}

.anesthesia-content{
    display: flex;
    flex-direction: column;
}

.anesthesia-p{
    color: hsl(0, 0%, 50%);
    font-weight: normal;
}

@media only screen and (min-width: 768px){
    .anesthesia-content {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }
