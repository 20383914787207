@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.specialties {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  margin-top: 10px;
  background-color: white;
}

.specialty-types {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.specialty,
.specialty-ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.anatomy {
  text-align: center;
}

.specialty-list > li {
  line-height: 2;
  color: #276b90;
  width: 100%;
  height: 100%;
}

.specialty-list > li:hover,
.sub-headings:hover {
  cursor: pointer;
  border-bottom: 1px solid #0d2430;
  color: #35566e;
  transition: all 200ms;
}

.sub-headings,
.position-sub-heading {
  font-size: 18px;
  font-weight: bolder;
  color: #276b90;
}

.procedures {
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px rgba(17, 17, 26, 0.1);

  & > p {
    text-align: left;
  }
}

.procedure-video {
  display: flex;
  flex-direction: column;
}

.function-video{
  padding: 5px !important;
  box-shadow: none !important;
font-size: 14px !important;
font-weight: normal !important;
}



@media only screen and (min-width: 768px){

}
