.aseptic {
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: 15px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
}

.aseptic-lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #276b90;
}
