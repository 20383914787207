

.footer{
    font-size: 12px;
    padding: 10px;
}


@media only screen and (min-width: 768px){
    .footer{
        font-size: 20px !important;
    }
  }