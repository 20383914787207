* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
    font-size: 62.5%;
    /* This changes the root font size from 16px to 10px*/
}

.hide {
    display: none !important;
}

.show {
    display: flex !important;
}

iframe {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);

    }

    80% {
        transform: translateX(20px);

    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100px);

    }

    80% {
        transform: translateY(20px);

    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;

    }

    to {
        opacity: 1;
    }
}

@media only screen and (min-width: 768px) {
    iframe {
        // width: 500px;
        height: 300px;
    }

    .main-headings {
        font-size: 30px !important;
    }


    .sub-headings {
        font-size: 20px;
    }
}

@media only screen and (min-width: 992px) {
iframe {
width: 800px;
 height: 500px !important;
   }
}