@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.content-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 30px;
  padding: 10px;
  background-color:hsl(206, 98%, 97%);
  margin-top: 30px;
  animation: fadeIn 2s linear;
}