@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.operating-room-101{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 5px;
    line-height: 2;
    background-color: white;
box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.operating-items{
  display: flex;
  flex-direction: column;
}

.operating-room-video{
 width: 100%;
 height: 100%;
}

.operating-p{
    color: hsl(0, 0%, 50%);
    font-weight: normal;
}

@media only screen and (min-width: 768px){
    .operating-items {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }