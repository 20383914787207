@use "../../sass/utilities/mixins" as *;
@use "../../sass/utilities/variables" as *;


.img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}