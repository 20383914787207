@use "/src/sass/utilities/mixins" as *;
@use "/src/sass/utilities/variables" as *;

.positioning {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: 10px;
  padding: 10px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background-color: white;
}

.positioning-content {
  display: flex;
  flex-direction: column;
  align-items: right;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-right: 10px;
}

.position-heading {
  font-size: 20px;
}

.position-sub-heading {
  transform: translateX(60px);
}

.fa-arrow-right {
  transform: translateX(-10px);
}

.beds-accessories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  list-style: none;
}

.bed-position-accessories-lists {
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px rgba(17, 17, 26, 0.1);
}

@media only screen and (min-width: 768px) {
  .positioning-content {
    align-items: center !important;
    padding-right: 0px !important;
  }
  .position-sub-heading {
    transform: translateX(0px) !important;
  }
  .arrow-1 {
    transform: translateX(-50px);
  }

  .arrow-2 {
    transform: translateX(-80px);
  }

  .arrow-3 {
    transform: translateX(-20px);
  }
}
