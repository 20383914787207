@use "../src/sass/utilities/mixins"as *;
@use "../src/sass/utilities/variables"as *;

body{
    font-family:'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #777;
    padding: 10px;
}

.main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.logo-name{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5px;
    border-bottom: 1px solid hsla(0, 0%, 47%, 0.3);
    gap:2px;
    text-decoration: none;
    outline: none;
}

.logo{
    color:#054A91;
    font-size: 30px;     
}

.heading-primary{
    color: #276b90;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: bold;
    translate: 0 -2px;
}

span {
    display: inline-block;
}

h2{
    text-align: center;
}

.arrow-icon{
    position: fixed;
    border-radius: 5px;
    right: 15px;
    bottom: 15px;
    z-index: 100;
    padding: 5px;
    transition: all 200ms;
    cursor: pointer;

    .nav-arrow{
        display: inherit;
        width: inherit;
        height: inherit;
        font-size: 30px;
        color:#054A91;
        transition: all 200ms;
        padding: 5px;
        border-radius: 3px;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
      }
      .nav-arrow:hover {
        box-shadow: 0 0 5px #276b90;
        scale: 1.1;
    }
}

@media only screen and (min-width: 768px) {

    .logo{
        font-size: 50px;  
       
    }


    .heading-primary{
        font-size: 40px;
    }

}